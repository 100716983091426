<template>
  <div>
    <div v-show="show_sign_page === true" class="signing_container">
      <div class="sign_page_content">
        <div class="sign_page_title">Digital Signature 电子签名</div>
        <div class="sign_page_subtitle">Use the box below to enter your signature. If you’re using a touchscreen, you can sign directly. Otherwise, use the mouse on your computer. 请在以下方框内签名，触屏设备可直接手签，电脑设备可用鼠标签字</div>
        <SignCanvas  class="sign-canvas" ref="SignCanvas" :options="options" v-model="signValue"></SignCanvas>
        <div class="btn_layout">
          <button @click="canvasClear" class="resign_btn">Re-Sign 重新签名</button>
          <button @click="submitSign" class="sign_submit_btn">Submit 提交</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SignCanvas from 'sign-canvas'
import { mapMutations } from 'vuex'
export default {
  components: {
    SignCanvas
  },
  created () {
    if (this.$route.query.file_name & this.$route.query.token) {
      this.file_name = this.$route.query.file_name
      this.token = this.$route.query.token
    }
    console.log(this.$route.query)
    if (this.$store.state.layout.smallScren) {
      this.options.canvasWidth = 320
      this.options.canvasHeight = 250
    }
  },
  mounted () {
  },
  data () {
    return {
      file_name: null,
      token: null,
      sign_pic: null,
      show_sign_page: true,
      signValue: null,
      options: {
        isDpr: false, // 是否使用dpr兼容高倍屏 [Boolean] 可选
        lastWriteSpeed: 2, // 书写速度 [Number] 可选
        lastWriteWidth: 1, // 下笔的宽度 [Number] 可选
        lineCap: 'round',
        lineJoin: 'bevel',
        canvasWidth: 660, // canvas宽高 [Number] 可选
        canvasHeight: 300, // 高度  [Number] 可选
        isShowBorder: true, // 是否显示边框 [可选]
        bgColor: '#f8f8f8', // 背景色 [String] 可选
        borderWidth: 1, // 网格线宽度  [Number] 可选
        borderColor: '#ddd', // 网格颜色  [String] 可选
        writeWidth: 5, // 基础轨迹宽度  [Number] 可选
        maxWriteWidth: 30, // 写字模式最大线宽  [Number] 可选
        minWriteWidth: 5, // 写字模式最小线宽  [Number] 可选
        writeColor: '#101010', // 轨迹颜色  [String] 可选
        isSign: true, // 签名模式 [Boolean] 默认为非签名模式,有线框, 当设置为true的时候没有任何线框
        imgType: 'png' // 下载的图片格式  [String] 可选为 jpeg  canvas本是透明背景的
      }
    }
  },
  methods: {
    canvasClear () {
      this.$refs.SignCanvas.canvasClear()
      this.sign_pic = null
    },
    submitSign () {
      this.sign_pic = this.signValue
      this.$refs.SignCanvas.canvasClear()
      if (this.sign_pic) {
        this.$message.success('提交成功')
      } else {
        this.$message.error('提交失败 内容为空')
      }
    },
    ...mapMutations({ SetScroll: 'SetScroll' })

  },
  watch: {
  },
  computed: {
    getSignPic () {
      return this.sign_pic
    }
  }
}
</script>

<style>
.sign_icon{
  width: 40px;
  height: 40px;
  margin: 30px auto 5px;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
}
.sign_text{
  color: rgba(0,0,0,.45);
  text-align: center;
}
.signing_container{
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 3;
  background: white;
  padding: 10vh 0;
}
.img_container{
  height: 130px;
  width: 260px;
  border: 1px dashed rgba(0,0,0,.25);
  cursor: pointer;
}
.close_icon{
  position: absolute;
  top: 5vh;
  right: 5%;
}
.sign_page_title{
  font-size: 48px;
  text-align: center;
  font-weight: 300;
}
.sign_page_subtitle{
  font-size: 16px;
  line-height: 26px;
  font-weight: 300;
  margin: 30px auto;
  text-align: center;
}
#sign_page_canvas{
  margin: 20px auto;
  display: block;
  background: #f8f8f8;
}
.sign_page_content{
  max-width: 700px;
  margin: 0 auto;
}
.sign-canvas{
  margin: 0 auto;
}
.btn_layout{
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.resign_btn{
  border: 1px solid #ea7840;
  color: #ea7840;
  padding: 10px 10px;
  font-weight: 300;
  transition: all .3s;
  background: #fff;
}
.resign_btn:hover{
  background: #d36c39;
  color: #fff;
}
.sign_submit_btn{
  border: 1px solid #ea7840;
  background: #ea7840;
  color: #fff;
  padding: 10px 10px;
  font-weight: 300;
}
.sign_submit_btn:hover{
  background: #d36c39;
}
.sign_pic{
  width: 260px;
  height: 130px;
}
@media only screen and (max-width: 768px) {
.close_icon{
  position: absolute;
  top: 3vh;
  right: 5%;
}
.sign_page_title{
  font-size: 7vw;
  text-align: center;
  font-weight: 300;
}
.signing_container{
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 3;
  background: white;
  padding: 10vh 5vw;
}
.sign_page_subtitle{
  font-size: 16px;
  line-height: 26px;
  font-weight: 300;
  margin: 2vh auto;
  text-align: center;
}
}
</style>
